.App {
  text-align: center;
  margin: 0;
  background: transparent;
  > div,
  input,
  textarea {
    color: white !important;
    margin: 0;
    background: transparent;
    box-shadow: none;
    label {
      color: white !important;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.comment {
  background-color: #1f2a37;
  border: 1px solid #4b5663 !important;
  border-radius: 5px;
  p {
    text-align: left;
  }
  button {
    float: left;
  }
}

.MuiFormControl-root {
  margin: 0 !important;
}

.comment-form {
  align-items: center;
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 20px;
}

.comment-button {
  font-size: 0.875rem !important;
  width: 180px !important;
  height: 50px !important;
}

.general-button {
  cursor: pointer;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
  background-color: #2ea44f !important;
  box-shadow: 0 1px 1px rgba(18, 21, 26, 0.075);
  color: white !important;
  font-weight: bold !important;
}

.chat-icon {
  width: 20px;
  height: 20px;
}

.comment-input {
  margin-bottom: 2;
  background-color: #1f2a37;
  border: 1px solid #4b5663 !important;
  border-radius: 5px;

  .MuiInputBase-root {
    padding: 0px !important;
  }

  textarea {
    padding: 13px;
  }

  .Mui-focused {
    input,
    textarea {
      background-color: #374251;
    }
    fieldset {
      border-color: #2ea44f !important;
    }
  }

  :hover {
    fieldset {
      border-color: #2ea44f !important;
    }
  }
}

.char-limit {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.reply-comment-input {
  input {
    padding-right: 80px;
  }
}

.comment-content,
.reply-comment-content {
  hyphens: auto;
}
